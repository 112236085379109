import { defineRecipe } from '@chakra-ui/react';

export const markRecipe = defineRecipe({
	className: 'chakra-mark',
	base: {
		bg: 'transparent',
		color: 'inherit',
		whiteSpace: 'nowrap',
	},
	variants: {
		variant: {
			subtle: {
				bg: 'colorPalette.subtle',
				color: 'inherit',
			},
			solid: {
				bg: 'primaryDark.100',
				color: 'white',
			},
			text: {
				fontWeight: 'bold',
			},
			plain: {},
		},
	},
});
