import { defineTextStyles } from '@chakra-ui/react';

export const textStyles = defineTextStyles({
	'2xs': {
		value: {
			fontSize: '2xs',
		},
	},
	xs: {
		value: {
			fontSize: 'xs',
		},
	},
	sm: {
		value: {
			fontSize: 'sm',
		},
	},
	md: {
		value: {
			fontSize: 'md',
		},
	},
	lg: {
		value: {
			fontSize: 'lg',
			lineHeight: '1.2',
		},
	},
	xl: {
		value: {
			fontSize: 'xl',
			lineHeight: '1.2',
			fontFamily: 'gabarito',
			fontWeight: 'semibold',
		},
	},
	'2xl': {
		value: {
			fontSize: '2xl',
			lineHeight: '1.2',
			fontFamily: 'gabarito',
			fontWeight: 'semibold',
		},
	},
	'3xl': {
		value: {
			fontSize: '3xl',
			lineHeight: '1.2',
			fontFamily: 'gabarito',
			fontWeight: 'semibold',
		},
	},
	'4xl': {
		value: {
			fontSize: '4xl',
			lineHeight: '1.2',
			fontFamily: 'gabarito',
			fontWeight: 'semibold',
			letterSpacing: '-0.025em',
		},
	},
	'5xl': {
		value: {
			fontSize: '5xl',
			lineHeight: '1.2',
			fontFamily: 'gabarito',
			fontWeight: 'semibold',
			letterSpacing: '-0.025em',
		},
	},
	'6xl': {
		value: {
			fontSize: '6xl',
			lineHeight: '1.2',
			fontFamily: 'gabarito',
			fontWeight: 'semibold',
			letterSpacing: '-0.025em',
		},
	},
	none: {
		value: {},
	},
});
