import { defineTokens } from '@chakra-ui/react';

export const lineHeights = defineTokens.lineHeights({
	tight: {
		value: 1.15,
	},
	short: {
		value: 1.2,
	},
	moderate: {
		value: 1.5,
	},
});
