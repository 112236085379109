import { defineSlotRecipe } from '@chakra-ui/react';

export const fileUploadSlotRecipe = defineSlotRecipe({
	className: 'chakra-file-upload',
	slots: [
		'root',
		'dropzone',
		'item',
		'itemDeleteTrigger',
		'itemGroup',
		'itemName',
		'itemPreview',
		'itemPreviewImage',
		'itemSizeText',
		'label',
		'trigger',
		'itemContent',
		'dropzoneContent',
	],
	base: {
		root: {
			display: 'flex',
			flexDirection: 'column',
			gap: '4',
			width: '100%',
			alignItems: 'flex-start',
		},
		label: {
			fontWeight: 'medium',
			textStyle: 'sm',
		},
		dropzone: {
			background: 'bg',
			borderRadius: 'l3',
			borderWidth: '2px',
			borderStyle: 'dashed',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			gap: '4',
			justifyContent: 'center',
			minHeight: '2xs',
			px: '3',
			py: '2',
			transition: 'backgrounds',
			focusVisibleRing: 'outside',
			_hover: {
				bg: 'bg.subtle',
			},
			_dragging: {
				bg: 'colorPalette.subtle',
				borderStyle: 'solid',
				borderColor: 'colorPalette.solid',
			},
		},
		dropzoneContent: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',
			gap: '1',
			textStyle: 'sm',
		},
		item: {
			textStyle: 'sm',
			animationName: 'fade-in',
			animationDuration: 'moderate',
			background: 'bg',
			borderRadius: 'l2',
			borderWidth: '1px',
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			gap: '3',
			p: '4',
		},
		itemGroup: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			gap: '3',
		},
		itemName: {
			color: 'fg',
			fontWeight: 'medium',
			lineClamp: '1',
		},
		itemContent: {
			display: 'flex',
			flexDirection: 'column',
			gap: '0.5',
			flex: '1',
		},
		itemSizeText: {
			color: 'fg.lowContrast',
			textStyle: 'xs',
		},
		itemDeleteTrigger: {
			alignSelf: 'flex-start',
		},
		itemPreviewImage: {
			width: '10',
			height: '10',
			objectFit: 'scale-down',
		},
	},
	defaultVariants: {},
});
