import { defineSemanticTokens } from '@chakra-ui/react';

export const colors = defineSemanticTokens.colors({
	bg: {
		DEFAULT: {
			value: {
				_light: '{colors.white}',
				_dark: '{colors.black}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.gray.50}',
				_dark: '{colors.gray.950}',
			},
		},
		muted: {
			value: {
				_light: '{colors.gray.100}',
				_dark: '{colors.gray.900}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.gray.200}',
				_dark: '{colors.gray.800}',
			},
		},
		inverted: {
			value: {
				_light: '{colors.black}',
				_dark: '{colors.white}',
			},
		},
		panel: {
			value: {
				_light: '{colors.white}',
				_dark: '{colors.gray.700}',
			},
		},
		error: {
			value: {
				_light: '{colors.red.50}',
				_dark: '{colors.red.950}',
			},
		},
		warning: {
			value: {
				_light: '{colors.orange.50}',
				_dark: '{colors.orange.950}',
			},
		},
		success: {
			value: {
				_light: '{colors.green.50}',
				_dark: '{colors.green.950}',
			},
		},
		info: {
			value: {
				_light: '{colors.blue.50}',
				_dark: '{colors.blue.950}',
			},
		},
	},
	fg: {
		DEFAULT: {
			value: {
				_light: '{colors.gray.500}',
				_dark: '{colors.gray.300}',
			},
		},
		muted: {
			value: {
				_light: '{colors.gray.600}',
				_dark: '{colors.gray.400}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.gray.400}',
				_dark: '{colors.gray.500}',
			},
		},
		inverted: {
			value: {
				_light: '{colors.gray.50}',
				_dark: '{colors.black}',
			},
		},
		error: {
			value: {
				_light: '{colors.red.500}',
				_dark: '{colors.red.400}',
			},
		},
		warning: {
			value: {
				_light: '{colors.orange.600}',
				_dark: '{colors.orange.300}',
			},
		},
		success: {
			value: {
				_light: '{colors.green.600}',
				_dark: '{colors.green.300}',
			},
		},
		info: {
			value: {
				_light: '{colors.blue.600}',
				_dark: '{colors.blue.300}',
			},
		},
	},
	border: {
		DEFAULT: {
			value: {
				_light: '{colors.blackAlpha.200}',
				_dark: '{colors.whiteAlpha.300}',
			},
		},
		muted: {
			value: {
				_light: '{colors.gray.100}',
				_dark: '{colors.gray.900}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.gray.50}',
				_dark: '{colors.gray.950}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.gray.300}',
				_dark: '{colors.gray.700}',
			},
		},
		inverted: {
			value: {
				_light: '{colors.gray.800}',
				_dark: '{colors.gray.200}',
			},
		},
		error: {
			value: {
				_light: '{colors.red.500}',
				_dark: '{colors.red.400}',
			},
		},
		warning: {
			value: {
				_light: '{colors.orange.500}',
				_dark: '{colors.orange.400}',
			},
		},
		success: {
			value: {
				_light: '{colors.green.500}',
				_dark: '{colors.green.400}',
			},
		},
		info: {
			value: {
				_light: '{colors.blue.500}',
				_dark: '{colors.blue.400}',
			},
		},
	},
	gray: {
		contrast: {
			value: {
				_light: '{colors.gray.600}',
				_dark: '{colors.gray.200}',
			},
		},
		fg: {
			value: {
				_light: '{colors.gray.500}',
				_dark: '{colors.gray.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.gray.100}',
				_dark: '{colors.gray.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.gray.200}',
				_dark: '{colors.gray.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.gray.300}',
				_dark: '{colors.gray.700}',
			},
		},
		solid: {
			value: {
				_light: '{colors.gray.100}',
				_dark: '{colors.gray.600}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.gray.800}',
				_dark: '{colors.gray.200}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.grayAlphaLight}',
				_dark: '{colors.grayAlphaDark}',
			},
		},
	},
	red: {
		contrast: {
			value: {
				_light: 'white',
				_dark: 'white',
			},
		},
		fg: {
			value: {
				_light: '{colors.red.500}',
				_dark: '{colors.red.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.red.100}',
				_dark: '{colors.red.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.red.200}',
				_dark: '{colors.red.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.red.300}',
				_dark: '{colors.red.400}',
			},
		},
		solid: {
			value: {
				_light: '{colors.red.600}',
				_dark: '{colors.red.600}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.red.600}',
				_dark: '{colors.red.600}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.redAlpha.5}',
				_dark: '{colors.redAlpha.20}',
			},
		},
	},
	orange: {
		contrast: {
			value: {
				_light: 'white',
				_dark: 'black',
			},
		},
		fg: {
			value: {
				_light: '{colors.orange.500}',
				_dark: '{colors.orange.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.orange.100}',
				_dark: '{colors.orange.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.orange.200}',
				_dark: '{colors.orange.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.orange.300}',
				_dark: '{colors.orange.500}',
			},
		},
		solid: {
			value: {
				_light: '{colors.orange.600}',
				_dark: '{colors.orange.500}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.orange.600}',
				_dark: '{colors.orange.500}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.orangeAlpha.10}',
				_dark: '{colors.orangeAlpha.20}',
			},
		},
	},
	green: {
		contrast: {
			value: {
				_light: 'white',
				_dark: 'white',
			},
		},
		fg: {
			value: {
				_light: '{colors.green.500}',
				_dark: '{colors.green.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.green.100}',
				_dark: '{colors.green.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.green.200}',
				_dark: '{colors.green.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.green.300}',
				_dark: '{colors.green.500}',
			},
		},
		solid: {
			value: {
				_light: '{colors.green.500}',
				_dark: '{colors.green.500}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.green.600}',
				_dark: '{colors.green.600}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.greenAlpha.10}',
				_dark: '{colors.greenAlpha.20}',
			},
		},
	},
	blue: {
		contrast: {
			value: {
				_light: 'white',
				_dark: 'white',
			},
		},
		fg: {
			value: {
				_light: '{colors.blue.500}',
				_dark: '{colors.blue.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.blue.100}',
				_dark: '{colors.blue.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.blue.200}',
				_dark: '{colors.blue.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.blue.300}',
				_dark: '{colors.blue.500}',
			},
		},
		solid: {
			value: {
				_light: '{colors.blue.600}',
				_dark: '{colors.blue.600}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.blue.600}',
				_dark: '{colors.blue.600}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.blueAlpha.10}',
				_dark: '{colors.blueAlpha.20}',
			},
		},
	},
	yellow: {
		contrast: {
			value: {
				_light: 'black',
				_dark: 'black',
			},
		},
		fg: {
			value: {
				_light: '{colors.yellow.600}',
				_dark: '{colors.yellow.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.yellow.100}',
				_dark: '{colors.yellow.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.yellow.200}',
				_dark: '{colors.yellow.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.yellow.300}',
				_dark: '{colors.yellow.500}',
			},
		},
		solid: {
			value: {
				_light: '{colors.yellow.300}',
				_dark: '{colors.yellow.300}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.yellow.300}',
				_dark: '{colors.yellow.300}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.yellowAlpha.10}',
				_dark: '{colors.yellowAlpha.20}',
			},
		},
	},
	teal: {
		contrast: {
			value: {
				_light: 'white',
				_dark: 'white',
			},
		},
		fg: {
			value: {
				_light: '{colors.teal.500}',
				_dark: '{colors.teal.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.teal.100}',
				_dark: '{colors.teal.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.teal.200}',
				_dark: '{colors.teal.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.teal.300}',
				_dark: '{colors.teal.500}',
			},
		},
		solid: {
			value: {
				_light: '{colors.teal.600}',
				_dark: '{colors.teal.600}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.teal.600}',
				_dark: '{colors.teal.600}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.tealAlpha.10}',
				_dark: '{colors.tealAlpha.20}',
			},
		},
	},
	purple: {
		contrast: {
			value: {
				_light: 'white',
				_dark: 'white',
			},
		},
		fg: {
			value: {
				_light: '{colors.purple.500}',
				_dark: '{colors.purple.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.purple.100}',
				_dark: '{colors.purple.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.purple.200}',
				_dark: '{colors.purple.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.purple.300}',
				_dark: '{colors.purple.500}',
			},
		},
		solid: {
			value: {
				_light: '{colors.purple.600}',
				_dark: '{colors.purple.600}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.purple.600}',
				_dark: '{colors.purple.600}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.purpleAlpha.10}',
				_dark: '{colors.purpleAlpha.20}',
			},
		},
	},
	pink: {
		contrast: {
			value: {
				_light: 'white',
				_dark: 'white',
			},
		},
		fg: {
			value: {
				_light: '{colors.pink.500}',
				_dark: '{colors.pink.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.pink.100}',
				_dark: '{colors.pink.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.pink.200}',
				_dark: '{colors.pink.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.pink.300}',
				_dark: '{colors.pink.500}',
			},
		},
		solid: {
			value: {
				_light: '{colors.pink.600}',
				_dark: '{colors.pink.600}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.pink.600}',
				_dark: '{colors.pink.600}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.pinkAlpha.10}',
				_dark: '{colors.pinkAlpha.20}',
			},
		},
	},
	cyan: {
		contrast: {
			value: {
				_light: 'white',
				_dark: 'white',
			},
		},
		fg: {
			value: {
				_light: '{colors.cyan.500}',
				_dark: '{colors.cyan.300}',
			},
		},
		subtle: {
			value: {
				_light: '{colors.cyan.100}',
				_dark: '{colors.cyan.900}',
			},
		},
		muted: {
			value: {
				_light: '{colors.cyan.200}',
				_dark: '{colors.cyan.800}',
			},
		},
		emphasized: {
			value: {
				_light: '{colors.cyan.300}',
				_dark: '{colors.cyan.500}',
			},
		},
		solid: {
			value: {
				_light: '{colors.cyan.600}',
				_dark: '{colors.cyan.600}',
			},
		},
		focusRing: {
			value: {
				_light: '{colors.cyan.600}',
				_dark: '{colors.cyan.600}',
			},
		},
		accentAlpha: {
			value: {
				_light: '{colors.cyanAlpha.10}',
				_dark: '{colors.cyanAlpha.20}',
			},
		},
	},
});
