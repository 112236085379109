'use client';

import { forwardRef } from 'react';
import { AbsoluteCenter, Menu as ChakraMenu, Portal } from '@chakra-ui/react';
import { FiCheck, FiChevronRight } from '@shared/icons';

interface MenuContentProps extends ChakraMenu.ContentProps {
	portalled?: boolean;
	portalRef?: React.RefObject<HTMLElement>;
}

// eslint-disable-next-line react/display-name
export const MenuContent = forwardRef<HTMLDivElement, MenuContentProps>((props, ref) => {
	const { portalled = true, portalRef, ...rest } = props;
	return (
		<Portal disabled={!portalled} container={portalRef}>
			<ChakraMenu.Positioner>
				<ChakraMenu.Content ref={ref} {...rest} />
			</ChakraMenu.Positioner>
		</Portal>
	);
});

// eslint-disable-next-line react/display-name
export const MenuArrow = forwardRef<HTMLDivElement, ChakraMenu.ArrowProps>((props, ref) => (
	<ChakraMenu.Arrow ref={ref} {...props}>
		<ChakraMenu.ArrowTip />
	</ChakraMenu.Arrow>
));

// eslint-disable-next-line react/display-name
export const MenuCheckboxItem = forwardRef<HTMLDivElement, ChakraMenu.CheckboxItemProps>((props, ref) => (
	<ChakraMenu.CheckboxItem ref={ref} {...props}>
		<ChakraMenu.ItemIndicator hidden={false}>
			<FiCheck />
		</ChakraMenu.ItemIndicator>
		{props.children}
	</ChakraMenu.CheckboxItem>
));

// eslint-disable-next-line react/display-name
export const MenuRadioItem = forwardRef<HTMLDivElement, ChakraMenu.RadioItemProps>((props, ref) => {
	const { children, ...rest } = props;
	return (
		<ChakraMenu.RadioItem ps="8" ref={ref} {...rest}>
			<AbsoluteCenter axis="horizontal" left="4" asChild>
				<ChakraMenu.ItemIndicator>
					<FiCheck />
				</ChakraMenu.ItemIndicator>
			</AbsoluteCenter>
			<ChakraMenu.ItemText>{children}</ChakraMenu.ItemText>
		</ChakraMenu.RadioItem>
	);
});

// eslint-disable-next-line react/display-name
export const MenuItemGroup = forwardRef<HTMLDivElement, ChakraMenu.ItemGroupProps>((props, ref) => {
	const { title, children, ...rest } = props;
	return (
		<ChakraMenu.ItemGroup ref={ref} {...rest}>
			{title && <ChakraMenu.ItemGroupLabel userSelect="none">{title}</ChakraMenu.ItemGroupLabel>}
			{children}
		</ChakraMenu.ItemGroup>
	);
});

export interface MenuTriggerItemProps extends ChakraMenu.ItemProps {
	startIcon?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const MenuTriggerItem = forwardRef<HTMLDivElement, MenuTriggerItemProps>((props, ref) => {
	const { startIcon, children, ...rest } = props;
	return (
		<ChakraMenu.TriggerItem ref={ref} {...rest}>
			{startIcon}
			{children}
			<FiChevronRight />
		</ChakraMenu.TriggerItem>
	);
});

export const MenuRadioItemGroup = ChakraMenu.RadioItemGroup;
export const MenuContextTrigger = ChakraMenu.ContextTrigger;
export const MenuRoot = ChakraMenu.Root;
export const MenuSeparator = ChakraMenu.Separator;

export const MenuItem = ChakraMenu.Item;
export const MenuItemText = ChakraMenu.ItemText;
export const MenuItemCommand = ChakraMenu.ItemCommand;
export const MenuTrigger = ChakraMenu.Trigger;
