import { defineTokens } from '@chakra-ui/react';

export const letterSpacings = defineTokens.letterSpacings({
	tight: {
		value: '-0.02em',
	},
	wide: {
		value: '0.02em',
	},
});
