'use client';

import { forwardRef } from 'react';
import { IconButtonProps, ClientOnly, Icon } from '@chakra-ui/react';
import { ThemeProvider, useTheme, ThemeProviderProps } from 'next-themes';
import { FiSun, FiMoon, BsFillSunFill, BsFillMoonFill } from '@shared/icons';
import { Skeleton } from '@/components/ui/skeleton';
import { Switch } from '@/components/ui/switch';

export function ColorModeProvider(props: ThemeProviderProps) {
	return <ThemeProvider attribute="class" disableTransitionOnChange {...props} />;
}

export function useColorMode() {
	const { resolvedTheme, setTheme } = useTheme();
	const toggleColorMode = () => {
		setTheme(resolvedTheme === 'light' ? 'dark' : 'light');
	};
	return {
		colorMode: resolvedTheme,
		setColorMode: setTheme,
		toggleColorMode,
	};
}

export function useColorModeValue<T>(light: T, dark: T) {
	const { colorMode } = useColorMode();
	return colorMode === 'light' ? light : dark;
}

export function ColorModeIcon() {
	const { colorMode } = useColorMode();
	return colorMode === 'light' ? <FiSun /> : <FiMoon />;
}

type ColorModeButtonProps = Omit<IconButtonProps, 'aria-label'>;

// eslint-disable-next-line react/display-name
export const ColorModeButton = forwardRef<HTMLButtonElement, ColorModeButtonProps>(() => {
	const { toggleColorMode } = useColorMode();
	return (
		<ClientOnly fallback={<Skeleton boxSize="8" />}>
			<Switch
				colorPalette="blue"
				size="lg"
				onCheckedChange={toggleColorMode}
				trackLabel={{
					on: (
						<Icon color="yellow.400">
							<BsFillSunFill />
						</Icon>
					),
					off: (
						<Icon color="gray.400">
							<BsFillMoonFill />
						</Icon>
					),
				}}
			/>
		</ClientOnly>
	);
});
