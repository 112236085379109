import React, { createContext, useContext, useEffect } from 'react';
import { User } from '@shared/zod-schemas';
import { createClient } from '@/supabase/client';

export const AuthContext = createContext<User | undefined>(undefined);

export const useUser = () => useContext(AuthContext);

export function AuthContextProvider({ children, user }: { children: React.ReactNode; user?: User }) {
	useEffect(() => {
		const supabase = createClient();

		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange(async (event, session) => {
			if (event === 'INITIAL_SESSION' && session) {
				// remote supabase session expired, but local persists. Check it by getting user if it's still valid
				const { error } = await supabase.auth.getUser();
				if (error) {
					// tady dojde jakoby k dvojimu redirectu (nejdriv middleware, pak tohle). ale to je edge case kdyz se tomu uzivately globalne invaliduje session. To by se uz s tim localnim odhlasenim stat nemelo.
					window.location.href = '/login';
				}
			}
		});

		return () => subscription.unsubscribe();
	}, []);
	return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}
