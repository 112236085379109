import { Gabarito, Inter } from 'next/font/google';
import { createSystem, defaultBaseConfig, defineConfig, mergeConfigs, defineTokens } from '@chakra-ui/react';
import { animationStyles } from './animation-styles';
import { breakpoints } from './breakpoints';
import { globalCss } from './global-css';
import { keyframes } from './keyframes';
import { layerStyles } from './layer-styles';
import { recipes } from './recipes';
import { semanticTokens } from './semantic-tokens';
import { slotRecipes } from './slot-recipes';
import { textStyles } from './text-styles';
import { tokens } from './tokens';

const inter = Inter({
	weight: ['400', '500', '600', '700', '800'],
	subsets: ['latin'],
	display: 'swap',
});

const gabarito = Gabarito({
	weight: ['400', '500', '600', '700'],
	subsets: ['latin'],
	display: 'swap',
});

const customTokens = defineTokens({
	fonts: {
		heading: { value: gabarito.style.fontFamily },
		body: { value: inter.style.fontFamily },
		gabarito: { value: gabarito.style.fontFamily },
	},
	colors: {
		primaryDark: {
			70: { value: 'hsla(158, 81%, 31%, 0.7)' },
			100: { value: 'hsl(158, 81%, 31%)' },
			200: { value: 'hsl(158, 81%, 27%)' },
		},
		primaryLight: { value: 'hsl(123, 57%, 64%)' },
		secondaryDark: { value: 'hsl(40, 83%, 58%)' },
		secondaryLight: { value: 'hsl(42, 93%, 78%)' },

		// Neutral shades
		neutralDark: { value: 'hsl(156, 20%, 5%)' },
		neutralDarkAlpha: { value: 'hsla(156, 20%, 5%, 0.8)' },
		neutralLight: { value: 'hsl(159, 50%, 95%)' },
		neutralLightAlpha: { value: 'hsla(159, 50%, 95%, 0.8)' },

		grayAlphaLight: { value: 'hsla(0, 0%, 0%, 0.08)' },
		grayAlphaDark: { value: 'hsla(00, 0%, 100%, 0.16)' },

		// Primary shades with alpha
		primaryDarkAlpha: {
			10: { value: 'hsla(158, 81%, 31%, 0.1)' },
			20: { value: 'hsla(158, 81%, 31%, 0.2)' },
			30: { value: 'hsla(158, 81%, 31%, 0.3)' },
			40: { value: 'hsla(158, 81%, 31%, 0.4)' },
			50: { value: 'hsla(158, 81%, 31%, 0.5)' },
			60: { value: 'hsla(158, 81%, 31%, 0.6)' },
			70: { value: 'hsla(158, 81%, 31%, 0.7)' },
			80: { value: 'hsla(158, 81%, 31%, 0.8)' },
			90: { value: 'hsla(158, 81%, 31%, 0.9)' },
		},

		// Primary Light shades with alpha
		primaryLightAlpha: {
			10: { value: 'hsla(123, 57%, 64%, 0.1)' },
			20: { value: 'hsla(123, 57%, 64%, 0.2)' },
			30: { value: 'hsla(123, 57%, 64%, 0.3)' },
			40: { value: 'hsla(123, 57%, 64%, 0.4)' },
			50: { value: 'hsla(123, 57%, 64%, 0.5)' },
			60: { value: 'hsla(123, 57%, 64%, 0.6)' },
			70: { value: 'hsla(123, 57%, 64%, 0.7)' },
			80: { value: 'hsla(123, 57%, 64%, 0.8)' },
			90: { value: 'hsla(123, 57%, 64%, 0.9)' },
		},

		// Secondary shades with alpha
		secondaryAlpha: {
			10: { value: 'hsla(40, 30%, 58%, 0.1)' },
			20: { value: 'hsla(40, 30%, 58%, 0.2)' },
			30: { value: 'hsla(40, 30%, 58%, 0.3)' },
			40: { value: 'hsla(40, 30%, 58%, 0.4)' },
			50: { value: 'hsla(40, 30%, 58%, 0.5)' },
			60: { value: 'hsla(40, 30%, 58%, 0.6)' },
			70: { value: 'hsla(40, 30%, 58%, 0.7)' },
			80: { value: 'hsla(40, 30%, 58%, 0.8)' },
			90: { value: 'hsla(40, 30%, 58%, 0.9)' },
		},

		// Secondary Light shades with alpha
		secondaryLightAlpha: {
			10: { value: 'hsla(42, 93%, 78%, 0.1)' },
			20: { value: 'hsla(42, 93%, 78%, 0.2)' },
			30: { value: 'hsla(42, 93%, 78%, 0.3)' },
			40: { value: 'hsla(42, 93%, 78%, 0.4)' },
			50: { value: 'hsla(42, 93%, 78%, 0.5)' },
			60: { value: 'hsla(42, 93%, 78%, 0.6)' },
			70: { value: 'hsla(42, 93%, 78%, 0.7)' },
			80: { value: 'hsla(42, 93%, 78%, 0.8)' },
			90: { value: 'hsla(42, 93%, 78%, 0.9)' },
		},

		// Red shades with alpha
		redAlpha: {
			5: { value: 'hsla(0, 76%, 57%, 0.05)' },
			10: { value: 'hsla(0, 76%, 57%, 0.1)' },
			20: { value: 'hsla(0, 76%, 57%, 0.2)' },
			30: { value: 'hsla(0, 76%, 57%, 0.3)' },
			40: { value: 'hsla(0, 76%, 57%, 0.4)' },
			50: { value: 'hsla(0, 76%, 57%, 0.5)' },
			60: { value: 'hsla(0, 76%, 57%, 0.6)' },
			70: { value: 'hsla(0, 76%, 57%, 0.7)' },
			80: { value: 'hsla(0, 76%, 57%, 0.8)' },
			90: { value: 'hsla(0, 76%, 57%, 0.9)' },
		},

		greenAlpha: {
			10: { value: 'hsla(148, 48%, 43%, 0.1)' },
			20: { value: 'hsla(148, 48%, 43%, 0.2)' },
			30: { value: 'hsla(148, 48%, 43%, 0.3)' },
			40: { value: 'hsla(148, 48%, 43%, 0.4)' },
			50: { value: 'hsla(148, 48%, 43%, 0.5)' },
			60: { value: 'hsla(148, 48%, 43%, 0.6)' },
			70: { value: 'hsla(148, 48%, 43%, 0.7)' },
			80: { value: 'hsla(148, 48%, 43%, 0.8)' },
			90: { value: 'hsla(148, 48%, 43%, 0.9)' },
		},

		blueAlpha: {
			10: { value: 'hsla(209, 62%, 50%, 0.1)' },
			20: { value: 'hsla(209, 62%, 50%, 0.2)' },
			30: { value: 'hsla(209, 62%, 50%, 0.3)' },
			40: { value: 'hsla(209, 62%, 50%, 0.4)' },
			50: { value: 'hsla(209, 62%, 50%, 0.5)' },
			60: { value: 'hsla(209, 62%, 50%, 0.6)' },
			70: { value: 'hsla(209, 62%, 50%, 0.7)' },
			80: { value: 'hsla(209, 62%, 50%, 0.8)' },
			90: { value: 'hsla(209, 62%, 50%, 0.9)' },
		},

		yellowAlpha: {
			10: { value: 'hsla(40, 67%, 51%, 0.1)' },
			20: { value: 'hsla(40, 67%, 51%, 0.2)' },
			30: { value: 'hsla(40, 67%, 51%, 0.3)' },
			40: { value: 'hsla(40, 67%, 51%, 0.4)' },
			50: { value: 'hsla(40, 67%, 51%, 0.5)' },
			60: { value: 'hsla(40, 67%, 51%, 0.6)' },
			70: { value: 'hsla(40, 67%, 51%, 0.7)' },
			80: { value: 'hsla(40, 67%, 51%, 0.8)' },
			90: { value: 'hsla(40, 67%, 51%, 0.9)' },
		},

		orangeAlpha: {
			10: { value: 'hsla(24, 75%, 50%, 0.1)' },
			20: { value: 'hsla(24, 75%, 50%, 0.2)' },
			30: { value: 'hsla(24, 75%, 50%, 0.3)' },
			40: { value: 'hsla(24, 75%, 50%, 0.4)' },
			50: { value: 'hsla(24, 75%, 50%, 0.5)' },
			60: { value: 'hsla(24, 75%, 50%, 0.6)' },
			70: { value: 'hsla(24, 75%, 50%, 0.7)' },
			80: { value: 'hsla(24, 75%, 50%, 0.8)' },
			90: { value: 'hsla(24, 75%, 50%, 0.9)' },
		},

		tealAlpha: {
			10: { value: 'hsla(179, 51%, 39%, 0.1)' },
			20: { value: 'hsla(179, 51%, 39%, 0.2)' },
			30: { value: 'hsla(179, 51%, 39%, 0.3)' },
			40: { value: 'hsla(179, 51%, 39%, 0.4)' },
			50: { value: 'hsla(179, 51%, 39%, 0.5)' },
			60: { value: 'hsla(179, 51%, 39%, 0.6)' },
			70: { value: 'hsla(179, 51%, 39%, 0.7)' },
			80: { value: 'hsla(179, 51%, 39%, 0.8)' },
			90: { value: 'hsla(179, 51%, 39%, 0.9)' },
		},

		cyanAlpha: {
			10: { value: 'hsla(190, 100%, 42%, 0.1)' },
			20: { value: 'hsla(190, 100%, 42%, 0.2)' },
			30: { value: 'hsla(190, 100%, 42%, 0.3)' },
			40: { value: 'hsla(190, 100%, 42%, 0.4)' },
			50: { value: 'hsla(190, 100%, 42%, 0.5)' },
			60: { value: 'hsla(190, 100%, 42%, 0.6)' },
			70: { value: 'hsla(190, 100%, 42%, 0.7)' },
			80: { value: 'hsla(190, 100%, 42%, 0.8)' },
			90: { value: 'hsla(190, 100%, 42%, 0.9)' },
		},

		purpleAlpha: {
			10: { value: 'hsla(259, 59%, 59%, 0.1)' },
			20: { value: 'hsla(259, 59%, 59%, 0.2)' },
			30: { value: 'hsla(259, 59%, 59%, 0.3)' },
			40: { value: 'hsla(259, 59%, 59%, 0.4)' },
			50: { value: 'hsla(259, 59%, 59%, 0.5)' },
			60: { value: 'hsla(259, 59%, 59%, 0.6)' },
			70: { value: 'hsla(259, 59%, 59%, 0.7)' },
			80: { value: 'hsla(259, 59%, 59%, 0.8)' },
			90: { value: 'hsla(259, 59%, 59%, 0.9)' },
		},

		pinkAlpha: {
			10: { value: 'hsla(329, 64%, 54%, 0.1)' },
			20: { value: 'hsla(329, 64%, 54%, 0.2)' },
			30: { value: 'hsla(329, 64%, 54%, 0.3)' },
			40: { value: 'hsla(329, 64%, 54%, 0.4)' },
			50: { value: 'hsla(329, 64%, 54%, 0.5)' },
			60: { value: 'hsla(329, 64%, 54%, 0.6)' },
			70: { value: 'hsla(329, 64%, 54%, 0.7)' },
			80: { value: 'hsla(329, 64%, 54%, 0.8)' },
			90: { value: 'hsla(329, 64%, 54%, 0.9)' },
		},
	},
});

// Vytvoření systému a přidání dalších konfiguračních možností
const customConfig = defineConfig({
	theme: {
		semanticTokens: {
			colors: {
				bg: {
					card: { value: { base: '{colors.white}', _dark: '{colors.gray.700}' } },
					baseMain: { value: { base: '{colors.gray.50}', _dark: '{colors.gray.800}' } }, // base color (problem with color name)
					cardInner: { value: { base: '{colors.gray.50}', _dark: '{colors.gray.600}' } },
					cardUpper: { value: { base: '{colors.gray.100}', _dark: '{colors.gray.500}' } },
					disabled: { value: { base: '{colors.gray.100}', _dark: '{colors.gray.600}' } },
					neutralAccentAlpha: {
						value: { base: 'hsl(202, 32%, 61%, 0.1)', _dark: 'hsl(202, 32%,  61%, 0.1)' },
					},
					primaryAccentAlpha: {
						value: { base: '{colors.primaryDarkAlpha.10}', _dark: '{colors.primaryLightAlpha.10}' },
					},
					grayAccentAlpha: {
						value: { base: '{colors.grayAlphaLight}', _dark: '{colors.grayAlphaDark}' },
					},
					redAccentAlpha: { value: { base: '{colors.redAlpha.5}', _dark: '{colors.redAlpha.20}' } },
					orangeAccentAlpha: { value: { base: '{colors.orangeAlpha.10}', _dark: '{colors.orangeAlpha.20}' } },
					yellowAccentAlpha: { value: { base: '{colors.yellowAlpha.10}', _dark: '{colors.yellowAlpha.20}' } },
					greenAccentAlpha: {
						value: { base: '{colors.greenAlpha.10}', _dark: '{colors.greenAlpha.20}' },
					},
					tealAccentAlpha: { value: { base: '{colors.tealAlpha.10}', _dark: '{colors.tealAlpha.20}' } },
					blueAccentAlpha: { value: { base: '{colors.blueAlpha.10}', _dark: '{colors.blueAlpha.20}' } },
					cyanAccentAlpha: {
						value: { base: '{colors.cyanAlpha.10}', _dark: '{colors.cyanAlpha.20}' },
					},
					purpleAccentAlpha: {
						value: { base: '{colors.purpleAlpha.10}', _dark: '{colors.purpleAlpha.20}' },
					},
					pinkAccentAlpha: { value: { base: '{colors.pinkAlpha.10}', _dark: '{colors.pinkAlpha.20}' } },
				},
				fg: {
					highContrast: { value: { base: '{colors.gray.700}', _dark: '{colors.gray.100}' } },
					normalContrast: { value: { base: '{colors.gray.600}', _dark: '{colors.gray.200}' } },
					mediumContrast: { value: { base: '{colors.gray.500}', _dark: '{colors.gray.300}' } },
					lowContrast: { value: { base: '{colors.gray.400}', _dark: '{colors.gray.500}' } },
					disabledText: { value: { base: '{colors.gray.400}', _dark: '{colors.gray.500}' } },
					primaryText: { value: { base: '{colors.primaryDark.100}', _dark: '{colors.primaryLight}' } },
					secondaryText: { value: { base: '{colors.secondaryDark}', _dark: '{colors.secondaryLight}' } },
					redText: { value: { base: '{colors.red.500}', _dark: '{colors.red.300}' } },
					orangeText: { value: { base: '{colors.orange.500}', _dark: '{colors.orange.300}' } },
					yellowText: { value: { base: '{colors.yellow.600}', _dark: '{colors.yellow.300}' } },
					greenText: { value: { base: '{colors.green.500}', _dark: '{colors.green.300}' } },
					tealText: { value: { base: '{colors.teal.500}', _dark: '{colors.teal.300}' } },
					blueText: { value: { base: '{colors.blue.500}', _dark: '{colors.blue.300}' } },
					cyanText: { value: { base: '{colors.cyan.500}', _dark: '{colors.cyan.300}' } },
					purpleText: { value: { base: '{colors.purple.500}', _dark: '{colors.purple.300}' } },
					pinkText: { value: { base: '{colors.pink.500}', _dark: '{colors.pink.300}' } },
					border: { value: { base: '{colors.blackAlpha.200}', _dark: '{colors.whiteAlpha.300}' } },
				},
			},
		},
		tokens: customTokens,
	},
});

const themeConfig = defineConfig({
	preflight: true,
	cssVarsPrefix: 'chakra',
	cssVarsRoot: ':where(:root, :host)',
	globalCss,
	theme: {
		breakpoints,
		keyframes,
		tokens,
		semanticTokens,
		recipes,
		slotRecipes,
		textStyles,
		layerStyles,
		animationStyles,
	},
});

export const customTheme = createSystem(defaultBaseConfig, mergeConfigs(themeConfig, customConfig));
