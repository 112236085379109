import { defineTokens } from '@chakra-ui/react';

export const sizes = defineTokens.sizes({
	'1': {
		value: '0.25rem',
	},
	'2': {
		value: '0.5rem',
	},
	'3': {
		value: '0.75rem',
	},
	'4': {
		value: '1rem',
	},
	'5': {
		value: '1.25rem',
	},
	'6': {
		value: '1.5rem',
	},
	'7': {
		value: '1.75rem',
	},
	'8': {
		value: '2rem',
	},
	'9': {
		value: '2.25rem',
	},
	'10': {
		value: '2.5rem',
	},
	'11': {
		value: '2.75rem',
	},
	'12': {
		value: '3rem',
	},
	'14': {
		value: '3.5rem',
	},
	'16': {
		value: '4rem',
	},
	'20': {
		value: '5rem',
	},
	'24': {
		value: '6rem',
	},
	'28': {
		value: '7rem',
	},
	'32': {
		value: '8rem',
	},
	'36': {
		value: '9rem',
	},
	'40': {
		value: '10rem',
	},
	'44': {
		value: '11rem',
	},
	'48': {
		value: '12rem',
	},
	'52': {
		value: '13rem',
	},
	'56': {
		value: '14rem',
	},
	'60': {
		value: '15rem',
	},
	'64': {
		value: '16rem',
	},
	'72': {
		value: '18rem',
	},
	'80': {
		value: '20rem',
	},
	'96': {
		value: '24rem',
	},
	'3xs': {
		value: '14rem',
	},
	'2xs': {
		value: '16rem',
	},
	xs: {
		value: '18rem',
	},
	sm: {
		value: '24rem',
	},
	md: {
		value: '28rem',
	},
	lg: {
		value: '32rem',
	},
	xl: {
		value: '36rem',
	},
	'2xl': {
		value: '42rem',
	},
	'3xl': {
		value: '48rem',
	},
	'4xl': {
		value: '56rem',
	},
	'5xl': {
		value: '64rem',
	},
	'6xl': {
		value: '72rem',
	},
	'7xl': {
		value: '80rem',
	},
	'8xl': {
		value: '90rem',
	},
	'0.5': {
		value: '0.125rem',
	},
	'1.5': {
		value: '0.375rem',
	},
	'2.5': {
		value: '0.625rem',
	},
	'3.5': {
		value: '0.875rem',
	},
	'4.5': {
		value: '1.125rem',
	},
	'1/2': {
		value: '50%',
	},
	'1/3': {
		value: '33.333333%',
	},
	'2/3': {
		value: '66.666667%',
	},
	'1/4': {
		value: '25%',
	},
	'3/4': {
		value: '75%',
	},
	'1/5': {
		value: '20%',
	},
	'2/5': {
		value: '40%',
	},
	'3/5': {
		value: '60%',
	},
	'4/5': {
		value: '80%',
	},
	'1/6': {
		value: '16.666667%',
	},
	'2/6': {
		value: '33.333333%',
	},
	'3/6': {
		value: '50%',
	},
	'4/6': {
		value: '66.666667%',
	},
	'5/6': {
		value: '83.333333%',
	},
	'1/12': {
		value: '8.333333%',
	},
	'2/12': {
		value: '16.666667%',
	},
	'3/12': {
		value: '25%',
	},
	'4/12': {
		value: '33.333333%',
	},
	'5/12': {
		value: '41.666667%',
	},
	'6/12': {
		value: '50%',
	},
	'7/12': {
		value: '58.333333%',
	},
	'8/12': {
		value: '66.666667%',
	},
	'9/12': {
		value: '75%',
	},
	'10/12': {
		value: '83.333333%',
	},
	'11/12': {
		value: '91.666667%',
	},
	max: {
		value: 'max-content',
	},
	min: {
		value: 'min-content',
	},
	fit: {
		value: 'fit-content',
	},
	prose: {
		value: '60ch',
	},
	full: {
		value: '100%',
	},
	dvh: {
		value: '100dvh',
	},
	svh: {
		value: '100svh',
	},
	lvh: {
		value: '100lvh',
	},
	dvw: {
		value: '100dvw',
	},
	svw: {
		value: '100svw',
	},
	lvw: {
		value: '100lvw',
	},
	vw: {
		value: '100vw',
	},
	vh: {
		value: '100vh',
	},
});
